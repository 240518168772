import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Base64 } from 'js-base64';
import { GlobalService } from 'src/app/_common/global';
import { LineItem } from 'src/app/_models/lineItem.model';
import { ProductService } from 'src/app/_services/product.service';
import { ShopifyService } from 'src/app/_services/shopify.service';
import { RoyalQuickViewComponent } from 'src/app/royal/widgets-royal/royal-quick-view/royal-quick-view.component';
import { CartModalComponent } from 'src/app/shared/components/modal/cart-modal/cart-modal.component';


@Component({
    selector: 'app-product-list-view',
    templateUrl: './product-list-view.component.html',
    styleUrls: ['./product-list-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductListViewComponent implements OnInit {
    @Input() product: any;
    @Input() currency: any = this.productService.Currency; // Default Currency
    @Input() thumbnail: boolean = false; // Default False
    @Input() onHowerChangeImage: boolean = false; // Default False
    @Input() cartModal: boolean = false; // Default False
    @Input() loader: boolean = false;
    @ViewChild("quickView") QuickView: RoyalQuickViewComponent;
    @ViewChild("cartModal") CartModal: any;
    public ImageSrc: string

    constructor(
        private productService: ProductService,
        public _global: GlobalService,
        private _shopifyService: ShopifyService
    ) { }

    ngOnInit(): void {
        if (this.loader) {
            setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
        }
    }

    // Get Product Color
    Color(variants) {
        const uniqColor = [];
        for (let i = 0; i < Object.keys(variants).length; i++) {
            if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
                uniqColor.push(variants[i].color)
            }
        }
        return uniqColor;
    }

    // Change Variants
    ChangeVariants(color, product) {
        product.variants.map((item) => {
            if (item.color === color) {
                product.images.map((img) => {
                    if (img.image_id === item.image_id) {
                        this.ImageSrc = img.src;
                    }
                })
            }
        })
    }

    // Change Variants Image
    ChangeVariantsImage(src) {
        this.ImageSrc = src;
    }

    // addToCart(product: any) {
    //   this.productService.addToCart(product);
    // }

    addToWishlist(product: any) {
        this.productService.addToWishlist(product);
    }

    addToCompare(product: any) {
        this.productService.addToCompare(product);
    }

    addToCart(product) {
        // console.log(product);
        let productVariant = product.externalId;
        const valId = "gid://shopify/Product/" + product.sku;
        let id = Base64.encode(valId);
        this._shopifyService.getProductById(id).then((prod: any) => {
            let variantVal;
            Object.entries(prod.variants).forEach(([key, value]) => {
                if (value["id"] == Base64.encode(productVariant)) {
                    variantVal = value;
                }
            });
            if (variantVal) {
                const lineItem = new LineItem(variantVal[0], 1, product.sku, product.name);
                console.log(lineItem);
                this._global.addItemToCart(lineItem);
            }
        })
        // this._global.gtagClick('add_to_cart', { 'url': this._global.getCurrentUrl() + '/product/' + this.productId, id: this.productId, 'quantity': this.qty_count });
        // const lineItem = new LineItem(this.variants, this.qty_count, this.productId, this.product_name);
        // this._global.addItemToCart(lineItem);
    }
}