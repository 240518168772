import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/_common/global';
import { CommonPipe } from 'src/app/_pipe/common.pipe';
import { ProductService } from 'src/app/_services/product.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-product-views',
  templateUrl: './product-views.component.html',
  styleUrls: ['./product-views.component.scss'],
  providers: [CommonPipe],
  encapsulation: ViewEncapsulation.None
})
export class ProductViewsComponent implements OnInit {
  public isMember = false;
  public cart_summary: any = [];
  public products: any = [];
  public request: any = [];
  public adminTagImage: any = [];
  public viewType = "grid";
  public viewCol: number = 25;
  public pageno = 1;
  public perPage = 12;
  public currentPage = 0;
  public sorts: any;
  public start = 0;
  public totalRecord = 0;
  public product_load = false;
  public data: any = [];
  public showMore = true;
  public users = [];
  public schema: any = [];
  public totalItem: Number = 0;
  public selectedproduct: any;
  public loading: false;
  public query_params: any = [];
  public facets_data: any = [];
  public isMobile = false;
  public isTablet = false;
  public isDesktop = false;
  public page_loading = true;
  public dataLoding = true;
  public params: any = [];
  public pageCounter = 1;
  public grid: string = 'col-xl-3 col-md-6';
  public layoutView: string = 'grid-view';
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  public sortBy: string; // Sorting Order
  public pageTitleName = 'Product';
  public all_products: any = [];
  public finished: any = false;
  public sortings = { "basePrice|asc": "Price,Low to High", "basePrice|desc": "Price,High to Low" };

  constructor(
    private _commonPipe: CommonPipe,
    private _productService: ProductService,
    private _global: GlobalService,
    private route: ActivatedRoute,
    private viewScroller: ViewportScroller,
    private router: Router
  ) {
    this.isMember = this._global.isMember();
    this.adminTagImage = this._global.tagImageList();
    this.isTablet = this._global.isTablet;
    this.isMobile = this._global.isMobile;
    this.isDesktop = this._global.isDesktop;
    this.sorts = "Sort";
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.query_params = params;
      // console.log(this.query_params);
      this.pageTitleName = params.category || params.brand;
      let p = {
        start: 0,
        rows: this.perPage
      }
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          p[key] = value;
        }
        if (key == "start" && params["start"]) {
          p.start = (params["start"] - 1) * this.perPage;
        }
      });
      this.currentPage = params["start"];
      this.request = p;
      this.loadAppData(p);
    });
  }

  loadAppData(p) {
    this.totalRecord = 0;
    this.products = [];
    this.dataLoding = true;
    this._productService.product(p).subscribe((data) => {
      this.dataLoding = false;
      this.finished = false;
      if (!_.isEmpty(this._global.getVal(data, 'response.docs', ''))) {
        this.params = this._global.getVal(data, 'request', '');
        this.products = data["response"]["docs"];
        console.log(this.products);
        this.totalRecord = this._global.getVal(data, 'response.numFound', '0');
        this.all_products = this._global.getVal(data, 'response.numFound', '0');
        let list = [];
        Object.entries(this.products).forEach(([key, value]) => {
          let l = {
            "@type": "ListItem",
            "position": parseInt(key) + 1,
            "url": this._global.SITE_URL + this._commonPipe.transform(value, ['produrl']),
            "name": value["name"],
          }
          list.push(l);
        });
        this.schema = {
          '@context': 'http://schema.org',
          '@type': 'ItemList',
          'itemListElement': list
        };
      }
    }, error => {
      this.dataLoding = true;
      console.log("error", JSON.stringify(error));
    });
  }

  // On scroll load the data
  onScrollDown() {
    if (!this.dataLoding) {
      this.dataLoding = false;
      if (this.totalRecord <= this.pageCounter * this.perPage) {
        this.dataLoding = false;
      } else {
        let p = this._global.generateParams(this.params, ['component', 'start', 'language', 'url', 'dialect']);
        p['start'] = this.pageCounter * this.perPage;
        this._productService.product(p).subscribe((data) => {
          this.finished = false;
          if (!_.isEmpty(this._global.getVal(data, 'response.docs', ''))) {
            this.products = this.products.concat(data["response"]["docs"]);
            let list = [];
            Object.entries(this.products).forEach(([key, value]) => {
              let l = {
                "@type": "ListItem",
                "position": parseInt(key) + 1,
                "url": this._global.SITE_URL + this._commonPipe.transform(value, ['produrl']),
                "name": value["name"],
              }
              list.push(l);
            });

            this.schema = {
              '@context': 'http://schema.org',
              '@type': 'ItemList',
              'itemListElement': list
            };
            this.dataLoding = false;
            this.pageCounter += 1;
            this.params = this._global.getVal(data, 'request', '');
          } else {
            this.dataLoding = false;
          }
        }, error => {
          console.log("error", JSON.stringify(error));
        });
      }
    }
  }

  addItems() {
    if(this.products.length >= this.all_products) {
      this.finished = true;
      return
    }
    this.onScrollDown();
    // this.products = this.all_products.slice(0, this.addItemCount);
  }

  // Infinite scroll
  public onScroll() {
    // add another items
    // this.pageCounter += 8;
    this.addItems();
  }
  

  // SortBy Filter
  sortByFilter(value) {
    console.log(value);
    // this.router.navigate([], { 
    //   relativeTo: this.route,
    //   queryParams: { sortBy: value ? value : null},
    //   queryParamsHandling: 'merge', // preserve the existing query params in the route
    //   skipLocationChange: false  // do trigger navigation
    // }).finally(() => {
    //   this.viewScroller.scrollToAnchor('products'); // Anchore Link
    // });
    this.sorts = this._global.getVal(this.sortings, value, 'Sort');
    let sort = value.split("|");
    let p = {
      start: 0,
      rows: this.perPage,
      sortString: sort[0],
      sortOrder: sort[1]
    };
    Object.entries(this.query_params).forEach(([key, value]) => {
      if (value) {
        p[key] = value;
      }
    });
    this.loadAppData(p);
  }

  // product Pagination
  setPage(page: number) {
    this.router.navigate([], { 
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Change Grid Layout
  updateGridLayout(value: string) {
    this.grid = value;
  }

  // Change Layout View
  updateLayoutView(value: string) {
    this.layoutView = value;
    if(value == 'list-view')
      this.grid = 'col-lg-12';
    else
      this.grid = 'col-xl-3 col-md-6';
  }

}
