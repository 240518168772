import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { GtagModule } from 'angular-gtag';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { CommonPipe } from './_pipe/common.pipe';
import { ImagePipe } from './_pipe/image.pipe';
import { GlobalService } from './_common/global';
import { AuthGuard } from './_guard/auth.guard';
import { LoggedinGuard } from './_guard/loggedin.guard';
import { ApiService } from './_services/api.service';
import { BrandsService } from './_services/brands.service';
import { LoginService } from './_services/login.service';
import { MemberService } from './_services/member.service';
import { ShopifyProductService } from './_services/shopify-product.service';
import { SignupService } from './_services/signup.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AboutComponent } from './royal/about/about.component';
import { RoyalBlogComponent } from './royal/blog/blog.component';
import { BlogsDetailsComponent } from './royal/blog/blogs-details/blogs-details.component';
import { CartRoyalComponent } from './royal/cart-royal/cart-royal.component';
import { ContactUsComponent } from './royal/contact-us/contact-us.component';
import { HelpComponent } from './royal/help/help.component';
import { HomeComponent } from './royal/home/home.component';
import { PrivacyAndSecurityComponent } from './royal/privacy-and-security/privacy-and-security.component';
import { ProductDetailsViewComponent } from './royal/products/product-views/product-list-view/product-details-view/product-details-view.component';
import { ProductViewsComponent } from './royal/products/product-views/product-views.component';
import { ProductsComponent } from './royal/products/products.component';
import { ReturnsComponent } from './royal/returns/returns.component';
import { RoyalForgetpasswordComponent } from './royal/royal-forgetpassword/royal-forgetpassword.component';
import { RoyalLoginComponent } from './royal/royal-login/royal-login.component';
import { RoyalSignupComponent } from './royal/royal-signup/royal-signup.component';
import { TermsOfUserComponent } from './royal/terms-of-user/terms-of-user.component';

import { BlogComponent } from './home/widgets/blog/blog.component';
import { RoyalFooterComponent } from './royal/common/royal-footer/royal-footer.component';
import { RoyalHeaderComponent } from './royal/common/royal-header/royal-header.component';
import { ProductListViewComponent } from './royal/products/product-views/product-list-view/product-list-view.component';
import { RoyalComponent } from './royal/royal.component';
import { BrandLogosComponent } from './royal/widgets-royal/brand-logos/brand-logos.component';
import { CustomerReviewsComponent } from './royal/widgets-royal/customer-reviews/customer-reviews.component';
import { HomeBlogsComponent } from './royal/widgets-royal/home-blogs/home-blogs.component';
import { HomeProductSlideComponent } from './royal/widgets-royal/home-product-slide/home-product-slide.component';
import { LoadingViewComponent } from './royal/widgets-royal/loading-view/loading-view.component';
import { RoyalLoadingComponent } from './royal/widgets-royal/royal-loading/royal-loading.component';
import { RoyalQuickViewComponent } from './royal/widgets-royal/royal-quick-view/royal-quick-view.component';
import { RoyalSocialsComponent } from './royal/widgets-royal/royal-socials/royal-socials.component';
import { GridComponent } from './shop/collection/widgets/grid/grid.component';
import { PaginationComponent } from './shop/collection/widgets/pagination/pagination.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SliderComponent } from './home/widgets/slider/slider.component';
import { ServicesComponent } from './home/widgets/services/services.component';
import { ShareSocialLinksComponent } from './royal/common/share-social-links/share-social-links.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

// AgmCoreModule.forRoot({
//   apiKey: 'AIzaSyDgqQQ0yXgrb0JPsMPygapUtz5OUJpZTpY',
//   libraries: ["places"]
// }),
// GtagModule.forRoot({ trackingId:'UA-131186387-1', trackPageviews: true }),

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    InfiniteScrollModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    AppRoutingModule,
    ShareButtonsModule,
    ShareIconsModule
  ],
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    ElementsComponent,
    CommonPipe,
    RoyalFooterComponent,
    RoyalHeaderComponent,
    HomeComponent,
    AboutComponent,
    ContactUsComponent,
    RoyalBlogComponent,
    HelpComponent,
    PrivacyAndSecurityComponent,
    TermsOfUserComponent,
    ReturnsComponent,
    ProductsComponent,
    SliderComponent,
    ServicesComponent,
    BlogComponent,
    // RoyalComponent,
    BlogsDetailsComponent,
    ProductViewsComponent,
    ProductListViewComponent,
    GridComponent,
    PaginationComponent,
    HomeBlogsComponent,
    BrandLogosComponent,
    LoadingViewComponent,
    CustomerReviewsComponent,
    RoyalLoginComponent,
    RoyalSignupComponent,
    RoyalForgetpasswordComponent,
    ProductDetailsViewComponent,
    RoyalSocialsComponent,
    RoyalLoadingComponent,
    RoyalQuickViewComponent,
    CartRoyalComponent,
    HomeProductSlideComponent,
    ShareSocialLinksComponent
  ],
  providers: [
    AuthGuard,
    ApiService,
    ShopifyProductService,
    BrandsService,
    LoggedinGuard,
    MemberService,
    LoginService,
    GlobalService,
    SignupService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})

export class AppModule { }
