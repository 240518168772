<section class="section-b-space" *ngIf="dataLoding">
    <app-royal-loading></app-royal-loading>
</section>
<app-breadcrumb [title]="product?.name" [breadcrumb]="'Product'" *ngIf="!dataLoding"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space" *ngIf="!dataLoding">
    <div class="collection-wrapper" *ngIf="!productLoad">
        <div class="container" *ngIf="prod.variants">
            <div class="row">
                <div class="col-lg-5">
                    <owl-carousel-o #owlCar [options]="ProductDetailsMainSliderConfig" class="product-slick">
                        <ng-container *ngFor="let variant of prod.variants; index as i">
                            <ng-template carouselSlide [id]="i">
                                <div [class.active]="i == activeSlide">
                                    <img [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="variant.image.src|common:['imagereszie','_500x500']" [alt]="variant.title" class="img-fluid">
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                    <div class="col-12 p-0" *ngIf="prod && prod.variants">
                        <div class="slider-nav">
                            <owl-carousel-o [options]="ProductDetailsThumbConfig" class="product-slick">
                                <ng-container *ngFor="let variant of prod.variants; index as i">
                                    <ng-template carouselSlide [id]="i">
                                        <div class="owl-thumb" [class.active]="i == activeSlide">
                                            <img [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="variant.image.src | common:['imagereszie','_100x100']" [alt]="variant.title" (click)="owlCar.to(activeSlide = i.toString()); checkProducts(variant)">
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 rtl-text">
                    <div class="product-right">
                        <h2>{{ product?.name | titlecase}}</h2>
                        <div class="product-prize">
                            <h3 *ngIf="product.discountPrice" class="mb-0 me-2" [ngClass]="product.basePrice ? 'me-2' : 'me-3'">
                                {{ product.discountPrice | number : '1.2-2' | currency:product?.currency:'symbol' }}
                            </h3>
                            <h4 *ngIf="product.basePrice" class="mb-0 me-3">
                                <del><span class="money"> {{ product.basePrice | number : '1.2-2' | currency:product?.currency:'symbol' }}</span></del>
                            </h4>
                            <bar-rating [rate]="4.5" [readOnly]="true"></bar-rating>
                        </div>
                        <!-- <div>
                            <div class="product-type">
                                <h4 class="mb-0 me-2">Product Type: </h4>
                                <h6 class="product-title mb-0" *ngIf="prod?.variants">{{prod?.variants[activeSlide].title | common:['sizecolor'] | titlecase}}</h6>
                            </div>
                            <ul class="color-variant">
                                <li *ngFor="let variant of prod.variants; index as i;" (click)="owlCar.to(activeSlide = i.toString()); getSize($event, variant)" [class.active]="activeSlide == i.toString()">
                                    <img src="{{ variants.image.src | common:['imagereszie','_30x30'] }}" />
                                </li>
                            </ul>
                        </div> -->
                        <!-- {{prod.variants | json}} -->
                        <app-stock-inventory [stock]="product?.quantity"></app-stock-inventory>
                        <div class="product-description border-product qty-products">
                            <span class="me-3">
                                <h4 class="avalibility mb-0" *ngIf="availableForSale"><span class="avalibility">In Stock</span></h4>
                                <h4 class="not-avalibility mb-0" *ngIf="!availableForSale"><span>Sold Out</span></h4>
                            </span>
                            <span>
                                {{productTitleView}}
                            </span>
                        </div>
                        <div class="product-description border-product w-100">
                            <div class="w-100 d-flex">
                                <span class="w-50 me-4">
                                    <label for="size">Size:</label>
                                    <ng-select [items]="prod.productsSizes" bindLabel="name" bindValue="name" groupBy="size" placeholder="Select Color" [searchable]="false" [(ngModel)]="selectedSize" (change)="onSizeChange($event)">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.data.image.src || 'Unnamed group'}}
                                        </ng-template>
                                    </ng-select>
                                </span>
                                <span class="w-50 me-4" *ngIf="prod.productsColors">
                                    <label for="color">Color:</label>
                                    <ng-select [items]="prod.productsColors" bindLabel="name" bindValue="name" groupBy="size" placeholder="Select Size" [searchable]="false" [(ngModel)]="selectedColor" (change)="onColorChange($event)">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.data.image.src || 'Unnamed group'}}
                                        </ng-template>
                                    </ng-select>
                                </span>
                            </div>
                            <div *ngIf="sizeError" class="text-danger">
                                Please select size.
                            </div>
                        </div>
                        <div class="product-description border-product qty-products" *ngIf="availableForSale">
                            <!-- <h6 class="product-title size-text" *ngIf="Size(product.variants).length">
                                select size
                                <span>
                                    <a href="javascrip:void(0)" (click)="SizeChart.openModal()">size chart</a>
                                </span>
                            </h6>
                            <div class="size-box" *ngIf="Size(product.variants).length">
                                <ul>
                                    <li [class.active]="selectedSize == size"
                                        *ngFor="let size of Size(product.variants)">
                                        <a href="javascript:void(0)" (click)="selectSize(size)">
                                        {{ size | titlecase }}
                                        </a>
                                    </li>
                                </ul>
                            </div> -->
                            <h6 class="product-title">quantity</h6>
                            <div class="qty-box me-3">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number" [value]="qty_count" disabled/>
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <span>Available Quantity : {{availableQuantity}}</span>
                        </div>
                        <div class="border-product product-buttons" *ngIf="availableForSale">
                            <a href="javascrip:void(0)" class="btn btn-solid" (click)="addToCart(currentSelectedProduct)">add to cart</a>
                            <a href="javascrip:void(0)" class="btn btn-solid" (click)="buy(currentSelectedProduct)">buy now</a>
                            <a href="javascrip:void(0)" class="btn btn-solid" target="_blank" href="https://api.whatsapp.com/send?phone=12014105803&text={{wappmsg}}+{{qty_count}}+ %0A Total : +{{variants.price.amount*qty_count}}+  %0A%0A +Order from Royalluxurylinen">Order on Whatsapp</a>
                        </div>
                        <div class="border-product">
                            <h6 class="product-title">product details</h6>
                            <p [innerHTML]="product?.description | common: ['limitChar', 500,'...']"></p>
                        </div>
                        <div class="border-product social-icons-product">
                            <h6 class="product-title">share it</h6>
                            <div class="product-icon">
                                <share-buttons theme="circles-dark" [include]="[ 'facebook', 'twitter', 'linkedin', 'reddit', 'whatsapp', 'telegram', 'email', 'tumblr', 'pinterest']" [showIcon]="true" [showText]="true" url="website_url" description="product?.description" twitterAccount="linen_royal"></share-buttons>
                                <!-- <br/>
                                <app-royal-socials [url]="website_url"></app-royal-socials>
                                <app-share-social-links [url]="website_url" [sharebtn]="share_btn_list"></app-share-social-links> -->
                                <form class="d-inline-block">
                                    <!-- <button class="wishlist-btn" (click)="addToWishlist(product)">
                                        <i class="fa fa-heart"></i>
                                        <span class="title-font">Add To WishList</span>
                                    </button> -->
                                </form>
                            </div>
                        </div>
                        <!-- <div class="border-product social-icons-product">
                            <div class="product-icon">
                                <form class="d-inline-block">
                                    <button class="wishlist-btn" (click)="addToWishlist(product)">
                                        <i class="fa fa-heart"></i>
                                        <span class="title-font">Add To WishList</span>
                                    </button>
                                </form>
                            </div>
                        </div> -->
                        <!-- <div class="border-product">
                            <app-countdown [date]="'Apr 21 2021'"></app-countdown>
                        </div> -->
                    </div>
                </div>
            </div>
            <section class="tab-product m-0">
                <div class="row">
                    <div class="col-sm-12 col-lg-12">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink>Description</a>
                                <ng-template ngbNavContent>
                                    <p *ngIf="product?.description" [innerHTML]="product.description"></p>
                                </ng-template>
                            </li>
                            <!-- <li [ngbNavItem]="2">
                                <a ngbNavLink>Video</a>
                                <ng-template ngbNavContent>
                                <div class="mt-3 text-center">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/BUWzX78Ye_8" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                                </div>
                                </ng-template>
                            </li> -->
                            <li [ngbNavItem]="2">
                                <a ngbNavLink>Write Review</a>
                                <ng-template ngbNavContent>
                                    <div *ngIf="isMember">
                                        <form class="theme-form">
                                            <div class="row">
                                                <!-- <div class="col-md-12 ">
                                                    <div class="media rating-sec">
                                                        <label>Rating</label>
                                                        <div class="media-body ms-3">
                                                            <div class="rating three-star">
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-md-6">
                                                    <label for="name">Name</label>
                                                    <input type="text" class="form-control" id="name" placeholder="Enter Your name" required>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="email">Email</label>
                                                    <input type="text" class="form-control" id="email" placeholder="Email" required>
                                                </div>
                                                <div class="col-md-12">
                                                    <label for="review">Review Title</label>
                                                    <input type="text" class="form-control" id="review" placeholder="Enter your Review Subjects" required>
                                                </div>
                                                <div class="col-md-12">
                                                    <label for="review">Review Title</label>
                                                    <textarea class="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows="6"></textarea>
                                                </div>
                                                <div class="col-md-12">
                                                    <button class="btn btn-solid" type="submit">Submit YOur Review</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div *ngIf="!isMember">
                                        <div>
                                            <p>Please login and write the review.</p>
                                            <button class="btn btn-solid" (click)="goToLogin()">Login</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>
                </div>
            </section>
        </div>
        <div class="container" *ngIf="!prod.variants">
            <div class="error-section">
                <h2 class="error-message">{{'Sorry, there are no details in this products' | translate}}</h2>
            </div>
        </div>
    </div>
</section>
