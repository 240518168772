import { Injectable } from '@angular/core'
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from '@angular/platform-browser';
import { LineItem } from '../_models/lineItem.model';
import { Cart } from '../_models/cart.model';
import { BehaviorSubject } from "rxjs";
import { Gtag } from 'angular-gtag';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()

export class GlobalService {
    public SUCCESS_NO = "1";
    public ERROR_NO = "-1";
    public mainLeftNav = 'unfolded';
    public EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    public MOBILE_REGEXP = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    public PASSWORD_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/; //8 characters1 numeric character
    public UPPERCASE_REGXP = /[A-Z]/; // 1 Upper Case
    public NUMBER_REGXP = /[0-9]/; // 1 Number
    public SPECIALA_REGXP = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;; // 1 Special
    public SITEID = "8eb9cd84-a604-30f3-850f-a76f318fdadc";
    public ORGID = "8c04f53a-f6f8-31e9-ad78-ecc334528ef1";
    public DOMAIN_NAME = "royalluxurylinen.socialretailapps.com";
    public SITE_NAME = "Fine Luxury Linen";
    public SITE_URL = "https://royalluxurylinen.com";
    public MOBILE = "+1-732-649-3609";
    public HOST_NAME = "royalluxurylinen.socialretailapps.com";
    public CHEKOUT_URL = "satdai.myshopify.com";
    public COMPONENTS_SMN = "Social Media";
    public COMPONENTS_SEC = "Social Ecommerce";
    public SESSION_KEY = this.DOMAIN_NAME.toUpperCase();
    public CART_STORAGE = this.SESSION_KEY + '_cart';
    public ORIGIN = 'https://auth.saavha.com';
    public VOICEIT_LANGUAGE = "en-US";
    public GTAG_ID = 'UA-131186387-1';
    public isLocalStorage = typeof (Storage) !== 'undefined';
    public SHOPIFY_KEY = "a06a43e8ed556a75c3aee2ac611b6746";
    public SHOPIFY_PASSWORD = "984d8f53fbe22e534bfbacb013e43a9a";
    public CARTSUMMARY: any = this.cartItems();
    public IMAGE_TYPE = ['.jpg', '.JPG', '.jpeg', '.png', '.PNG'];
    public ISADMIN_KEY = ['7026391e-86a8-37d3-96b5-5724b54836f7'];
    public mfsDomain = "royalluxurylinen.com"
    public TOKEN = "";
    public SESSION_KEY_VALUE = "";
    public MEMBER_DATA = "";
    public isMobile = false;
    public isTablet = false;
    public isDesktop = false;
    public cartObs: BehaviorSubject<Cart> = new BehaviorSubject(new Cart);
    public lineItemsObs: BehaviorSubject<LineItem[]> = new BehaviorSubject([]);
    public newlineItemObs: BehaviorSubject<LineItem> = new BehaviorSubject(null);
    public cartOpenCloseObs: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public loggingUserCheck: BehaviorSubject<any> = new BehaviorSubject(false);
    
    constructor(
        private deviceService: DeviceDetectorService,
        // private gtag: Gtag,
        private meta: Meta,
        private metaTitle: Title,
        private toastyService: ToastrService,
        private router: Router
    ) {
        let cart = new Cart;
        this.cartObs.next(cart);
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktop = this.deviceService.isDesktop();
        /// this.CARTSUMMARY =this.cartItems();
    }
    get cart() {
        return this.cartObs.getValue();
    }

    set cart(cart) {
        this.cartObs.next(cart);
    }

    get cartOpenClose() {
        return this.cartOpenCloseObs.getValue();
    }

    set cartOpenClose(value: boolean) {
        this.cartOpenCloseObs.next(value);
    }

    getLogedInUser(userObj) {
        this.loggingUserCheck.next(userObj);
    }
    
    public generateParams(obj, delparm) {
        let p = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!delparm.includes(key)) {
                p[key] = value;
            }
        });
        return p;
    }

    randomAlphaNumeric(length) {
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        return result;
    }

    getCurrentUrl() {
        return "https://www.royalluxurylinen.com";
    }
    
    cartItems() {
        let items = [];
        let cart = localStorage.getItem(this.CART_STORAGE);
        if (cart !== undefined && cart !== null) {
            items = JSON.parse(cart);
        }
        return items;
    }
    
    getCheckoutCustomerDetails() {
        let a = '&checkout[buyer_accepts_marketing]=true';
        let isMember = this.isMember();
        if (isMember) {
            a += '&checkout[email]=' + this.getSessionVal("email");
            a += '&checkout[shipping_address][first_name]=' + this.getSessionVal("firstName");
            a += '&checkout[shipping_address][last_name]=' + this.getSessionVal("lastName");
            // a+='&checkout[shipping_address][phone]='+this.getSessionVal("email");
        }
        return a;
    }
    
    getProductQty(varient) {
        let qty = 1;
        let cart = localStorage.getItem(this.CART_STORAGE);
        if (cart !== undefined && cart !== null) {
            let cart_list = JSON.parse(cart);
            Object.entries(cart_list).forEach(([key, value]) => {
                if (value["variantId"] == varient.id) {
                    qty = value["quantity"];
                }
            });
        }
        return qty;
    }
    
    addItemToCart(lineItem: LineItem) {
        let item_list = [];
        let cart = localStorage.getItem(this.CART_STORAGE);
        if (cart !== undefined && cart !== null) {
            let cart_list = JSON.parse(cart);
            let msg = "Product has been added to your cart";
            let available = false;
            Object.entries(cart_list).forEach(([key, value]) => {
                if (value["variantId"] == lineItem.variantId) {
                    value["quantity"] = lineItem.quantity;
                    item_list.push(value);
                    available = true;
                    msg = "Product Qunatity has been updated to your cart";
                } else {
                    item_list.push(value);
                }
            });
            if (!available) {
                item_list.push(lineItem);
            }
            localStorage.setItem(this.CART_STORAGE, JSON.stringify(item_list));
            this.CARTSUMMARY = this.cartItems();
            this.showMessage({ "type": "success", "message": msg, "title": "" });
        } else {
            item_list.push(lineItem);
            localStorage.setItem(this.CART_STORAGE, JSON.stringify(item_list));
            this.CARTSUMMARY = this.cartItems();
            this.showMessage({ "type": "success", "message": "Product has been added to your cart", "title": "" });
        }
    }

    urlencoder(str) {
        return encodeURIComponent(str)
            .replace(/!/g, '%21')
            .replace(/'/g, '%27')
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/\*/g, '%2A')
            .replace(/~/g, '%7E')
            .replace(/%20/g, '+');
    }
    
    getHomeSaleNotification() {
        let message = [
            { title: 'Mary in N, USA,', "name": "CASTLE HILL LONDON - T400 SINGLE PIECE COVERLET TAUPE", subtitle: '5 hours ago ', image: 'https://cdn.shopify.com/s/files/1/0088/3723/4803/products/TAUPE_909316bc-d1c0-4eba-bd22-122d8a568d9f_75x75.jpg?v=1548362518', "link": "https://royalluxurylinen.com/products/view/a4bc06cb-445d-3e9c-b982-f55fe2833f13" },
            { title: 'John in Nyk, USA,', "name": " CASTLE HILL LONDON - T400 SINGLE PIECE COVERLET SAGE", subtitle: '2 hours ago', image: 'https://cdn.shopify.com/s/files/1/0088/3723/4803/products/custom_resized_d4df4af2-c8b2-4369-b823-ffff947f1170_75x75.jpg?v=1548794869', "link": "https://royalluxurylinen.com/products/view/d2a18936-19dd-30f9-8be2-258849643fac" },
            { title: 'Patricia in Nyk, USA,', "name": " CASTLE HILL LONDON - COMFORTER 11 PIECES SET REGATTA PURPLE", subtitle: '1 hours ago', image: 'https://cdn.shopify.com/s/files/1/0088/3723/4803/products/ivory_278b04bc-cafb-4a4b-a368-2540b571add8_75x75.jpg?v=1548363391', "link": "https://royalluxurylinen.com/products/view/88a358d2-852f-38a8-adaa-697c07a0439a" },
            { title: 'Robert in Nyk, USA,', "name": " CASTLE HILL LONDON - COMFORTER 11 PIECES SET REGATTA BEIGE", subtitle: '30 minutes ago', image: 'https://cdn.shopify.com/s/files/1/0088/3723/4803/products/custom_resized_caac5919-e439-439c-871b-65e2ead7b5f9_75x75.png?v=1548363372', "link": "https://royalluxurylinen.com/products/view/e5bfcbd7-46d7-3880-b2af-863705ac23b0" },
            { title: 'Jennifer in Nyk, USA,', "name": " CASTLE HILL LONDON - COMFORTER 11 PIECES SET CLAIRE BEIGE", subtitle: '3 hours ago', image: 'https://cdn.shopify.com/s/files/1/0088/3723/4803/products/custom_resized_79256f41-b623-4a18-80a8-77796ae470b4_75x75.jpg?v=1548363356', "link": "https://royalluxurylinen.com/products/view/c1c93f86-4836-3388-a3a5-4e77ee369c85" },
            { title: 'Michael in Nyk, USA,', "name": " CASTLE HILL LONDON - COMFORTER 11 PIECES SET CARREY TAUPE", subtitle: '5 hours ago', image: 'https://cdn.shopify.com/s/files/1/0088/3723/4803/products/custom_resized_a9491f9a-9ed3-43a7-8a9e-41245683a4b0_75x75.jpg?v=1548363337', "link": "https://royalluxurylinen.com/products/view/5dee1dca-564c-3a6e-9e91-2ac07f830f94" },
            { title: 'Linda in Nyk, USA,', "name": " CASTLE HILL LONDON - COMFORTER 11 PIECES SET CARREY BLUE", subtitle: '6 hours ago', image: 'https://cdn.shopify.com/s/files/1/0088/3723/4803/products/custom_resized_b13ae1a2-f7b7-47c4-b415-87c7a154a5d1_75x75.jpg?v=1548363309', "link": "https://royalluxurylinen.com/products/view/6b77ed11-fcdf-3f38-b125-27a2d012435b" }
            /*  { title: 'William in Nyk, USA,', "name": "prodcut name8", subtitle: '11 hours ago', image: '', "link": "" },
             { title: 'Elizabeth in Nyk, USA,', "name": "prodcut name9", subtitle: '3 hours ago', image: '', "link": "" },
             { title: 'Barbara in Nyk, USA,', "name": "prodcut name10", subtitle: '4 hours ago', image: '', "link": "" },
             { title: 'Richard in Nyk, USA,', "name": "prodcut name10", subtitle: '4 hours ago', image: '', "link": "" },
             { title: 'Susan in Nyk, USA,', "name": "prodcut name10", subtitle: '4 hours ago', image: '', "link": "" },
             { title: 'Joseph in Nyk, USA,', "name": "prodcut name10", subtitle: '4 hours ago', image: '', "link": "" },
             { title: 'Jessica in Nyk, USA,', "name": "prodcut name10", subtitle: '4 hours ago', image: '', "link": "" },
             { title: 'Thomas in Nyk, USA,', "name": "prodcut name10", subtitle: '4 hours ago', image: '', "link": "" },
             { title: 'Sandra in Nyk, USA,', "name": "prodcut name10", subtitle: '4 hours ago', image: '', "link": "" },
             { title: 'Lisa in Nyk, USA,', "name": "prodcut name10", subtitle: '4 hours ago', image: '', "link": "" },
             { title: 'David in Nyk, USA,', "name": "prodcut name10", subtitle: '4 hours ago', image: '', "link": "" }, */
        ];
        return message;
    }

    removeItem(lineItem) {
        let lineItems = this.lineItemsObs.getValue();
        let index = lineItems.indexOf(lineItem);
        if (index != -1) {
            lineItems.splice(index, 1)
            this.lineItemsObs.next(lineItems);
        }
    }

    public cartSummary(response) {
        this.CARTSUMMARY = response;
    }

    public getRandomUuid() {
        return uuidv4();
    }
    
    public paramsRemoveNull(obj) {
        for (let key in obj) {
            if (_.isNil(obj[key])) {
                obj[key] = '';
            }
        }
        return obj;
    }
    
    public tagImageList() {
        return [
            { "tag": "bedding", "image": "https://cdn.shopify.com/s/files/1/0088/3723/4803/collections/FAQs-About-Deep-Pocket-Sheets-FB.jpg?v=1533648694" },
            { "tag": "bath", "image": "https://cdn.shopify.com/s/files/1/0088/3723/4803/collections/bj46_rug_r16_web.jpg?v=1531849807" },
            { "tag": "duvets", "image": "https://cdn.shopify.com/s/files/1/0088/3723/4803/collections/neutral-living-room-turquoise-chairs-4013601.jpg?v=1531850132" }
        ];
    }
    
    public showMessage(obj) {
        this.toastyService[obj['type']](obj["message"], obj["title"]);
    }
    
    public addMetaInfo(obj, pageType) {
        let description = String(this.getVal(obj, 'description', '')).substring(0, 320);
        description = description.replace(/<[^>]*>/g, '');
        this.metaTitle.setTitle('Royalluxurylinen - ' + this.getVal(obj, 'title', ''));
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ name: 'keywords', content: this.getVal(obj, 'keyword', '') + ',good thread count,bath mats and rugs,1500 thread count sheets,100 percent cotton sheets,best bedding sets,best bedding sets,best cotton sheets,best sheets for summer,good thread count,most comfortable bed sheets,best thread count for soft sheets,bedroom sheet sets,best bed sheet brand' });
        this.meta.updateTag({ name: 'title', content: 'Royalluxurylinen - ' + this.getVal(obj, 'title', '') });
        //  this.meta.updateTag({ name: 'apple-mobile-web-app-title', content: environment.appName });
        this.meta.updateTag({ property: 'og:title', content: 'Royalluxurylinen - ' + this.getVal(obj, 'title', '') })
        this.meta.updateTag({ property: 'og:description', content: description });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:price:amount', content: this.getVal(obj, 'price.amount', '') });
        this.meta.updateTag({ property: 'og:price:currency', content: 'USD' });
        this.meta.updateTag({ property: 'og:url', content: this.getVal(obj, 'website_url', this.SITE_URL) });
        if (pageType == 'view') {
            //   this.meta.updateTag({ property: 'og:url', content: this.getVal(obj, 'website_url', this.SITE_URL) });
            let images = obj.images;
            Object.entries(images).forEach(([key, value]) => {
                if (value["image"]) {
                    Object.entries(this.IMAGE_TYPE).forEach(([k, v]) => {
                        if (value["image"]["src"].search((v)) != "-1") {
                            this.meta.addTag({ property: 'og:image', content: (value["image"]["src"]).replace(v + '?v=', '_200x200' + v + '?v=') });
                        }
                    });
                }
            });
            Object.entries(images).forEach(([key, value]) => {
                if (value["image"]) {
                    Object.entries(this.IMAGE_TYPE).forEach(([k, v]) => {
                        if (value["image"]["src"].search((v)) != "-1") {
                            this.meta.addTag({ property: 'og:image:secure_url', content: (value["image"]["src"]).replace(v + '?v=', '_200x200' + v + '?v=') });
                        }
                    });
                }
            });
        }

        this.meta.updateTag({ name: "twitter:site", content: "@" + this.SITE_NAME });
        this.meta.updateTag({ name: "twitter:card", content: "summary_large_image" });
        this.meta.updateTag({ name: 'twitter:title', content: this.SITE_NAME + ' - ' + this.getVal(obj, 'title', '') })
        this.meta.updateTag({ name: 'twitter:description', content: description });
        this.meta.updateTag({ name: 'twitter:type', content: 'website' });
        if (pageType != 'view') {
            this.meta.addTag({ name: 'twitter:image', content: "https://royalluxurylinen.com/assets/images/logo.jpg" });
        }
        this.meta.updateTag({ name: 'twitter:url', content: this.getVal(obj, 'website_url', this.SITE_URL) });
    }

    public getMilliseconds() {
        return new Date().getTime();
    }

    public pathToMedia(obj) {
        let domina_name = this.getVal(this.userSiteDetails(), 'domainName', '');
        let image_url = "/cdn/" + this.getVal(obj, 'mediaType', '') + "/" + this.getVal(obj, 'id', '') + "/" + this.getVal(obj, 'size', 'xs') + "/" + this.getVal(obj, 'name', 'noname') + "_" + this.getMilliseconds() + ".png";
        return image_url
    }

    public asJsonObject = function (o) {
        let result = {};
        if (typeof o != 'undefined' && typeof o == 'string') {
            try {
                result = JSON.parse(o);
                result
            }
            catch (err) {
                console.log("asJsonObject error", err);
            }
        }
        return result;
    }

    // public gtagConfig() {
    //     //console.log("this.router.url --- ", this.router.url, " this.metaTitle.getTitle() === ", this.metaTitle.getTitle());
    //     /* (<any>window).gtag('config', this.GTAG_ID, {
    //       'page_title' : this.metaTitle.getTitle(),
    //       'page_path': this.router.url
    //     }); */
    //     this.gtag.pageview({
    //         page_title: this.metaTitle.getTitle(),
    //         page_path: this.router.url,
    //         page_location: this.router.url
    //     });
    // }

    // public gtagClick(event, obj) {
    //     this.gtag.event(event, obj);
    // }
    
    public removeSession() {
        localStorage.removeItem(this.SESSION_KEY);
        this.router.navigate(['']);
    }

    public getSession() {
        if (this.isLocalStorage) {
            let sessionObj = localStorage.getItem(this.SESSION_KEY);
            if (sessionObj !== undefined || sessionObj !== null) {
                let parsedObject = JSON.parse(sessionObj);
                return parsedObject;
            } else {
                //  console.log('GlobalService:getSession sessionObj=null for ', this.SESSION_KEY);
                return null;
            }
        }
        else {
            // console.log('GlobalService:getSession isLocalStorage=null for ', this.SESSION_KEY);
            return null;
        }
    }

    public isAuthenticated() {
        if (this.isMember()) {
            return true;
        }
        else {
            return false;
        }
    }

    public getToken() {
        var session = this.getSession();
        var token = "";
        if (session != null) {
            token = session["token"];
        }
        return token;
    }

    public isMember() {
        let session = this.getSession();
        if (session != null) {
            var sessionObject = session[this.SESSION_KEY];
            let role_name = this.getVal(this.userSiteDetails(), 'roleName', '');
            if (sessionObject != null) {
                if (sessionObject['isMember'] && sessionObject['email'] != '' && sessionObject['id'] != '') {
                    return true;
                } else if (role_name === "Site Admin" || role_name === "Org Admin") {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                console.log('GlobalService:isMember1=', false);
                return false;
            }
        }
        else {
            console.log('GlobalService:isMember2=', false);
            return false;
        }
    }

    public getMemberData() {
        let session = this.getSession();
        if (session != null) {
            this.MEMBER_DATA = (session[this.SESSION_KEY]) ? session[this.SESSION_KEY] : "";
        } else {
            this.MEMBER_DATA = "";
        }
        return this.MEMBER_DATA;
    }

    public getSessionVal(key) {
        var session = this.getSession();
        var value = '';
        if (session != null) {
            var sessionObject = session[this.SESSION_KEY];
            if (sessionObject != null) {
                value = sessionObject[key];
            }
        }
        return value;
    }

    public userSiteDetails() {
        var session = this.getSession();
        var detailsForSiteId;
        if (session != null) {
            var sessionObject = session[this.SESSION_KEY];
            if (sessionObject != null) {
                var userSiteDetails = sessionObject["userSiteDetails"];
                if (userSiteDetails != null) {
                    detailsForSiteId = userSiteDetails[this.SITEID];
                }
            }
        }
        return detailsForSiteId;
    }

    public getVal(o, key, def) {
        if (typeof key == 'undefined' || typeof o == 'undefined') {
            return def;
        }
        else if (typeof def == 'undefined') {
            return '';
        }
        else {
            var temp = o;
            key = String(key).split('.');
            for (var i in key) {
                if (typeof temp[key[i]] == 'undefined') {
                    return def;
                }
                else {
                    temp = temp[key[i]];
                }
            }
            return temp;
        }
    }
}
